module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wheyon","short_name":"Wheyon","start_url":"/","background_color":"#E2E8F0","theme_color":"#E2E8F0","display":"minimal-ui","icon":"src/images/wheyon-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Sarabun"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-47605900-20"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
